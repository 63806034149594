.fullpage-loader-holder {position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 99999;background: rgba(0, 0, 0, 0.9);animation: animate 1s infinite;}
.fullpage-loader-holder .img-loader { width: 120px; height: 120px;}
.fullpage-loader-holder .img-loader img{ width: 100%; height: 100%; object-fit: contain; animation: rotation 2s infinite linear;}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to {transform: rotate(359deg);}
}
@keyframes animate{
    0%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1657798150/hex-nft/assets/mynist_s1vmud_iladko.png"), auto;
    }
    4%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627404/hex-nft/assets/images/Layer_1_c8iiwt_z9n5bu.png"), auto;
    }
    8%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627405/hex-nft/assets/images/Layer_2_anxqpu_vjhjaj.png"), auto;
    }
    12%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627405/hex-nft/assets/images/Layer_3_wdew30_iivuds.png"), auto;
    }
    16%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627406/hex-nft/assets/images/Layer_4_sv2oga_qiuct1.png"), auto;
    }
    20%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627406/hex-nft/assets/images/Layer_5_q3lbsg_c2mloc.png"), auto;
    }
    24%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627406/hex-nft/assets/images/Layer_6_orhyki_gujwoq.png"), auto;
    }
    28%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627406/hex-nft/assets/images/Layer_7_atjxy6_ndfm6v.png"), auto;
    }
    32%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_8_circ6v_afs2r1.png"), auto;
    }
    36%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_9_im9kmf_szrp5t.png"), auto;
    }
    40%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_10_aaffy5_dathmv.png"), auto;
    }
    44%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_11_i0cgee_sv3w4b.png"), auto;
    }
    48%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_12_suewmj_ghya05.png"), auto;
    }
    52%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_13_zhewzi_a3npds.png"), auto;
    }
    56%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627407/hex-nft/assets/images/Layer_14_jrgdkl_wzpmcp.png"), auto;
    }
    60%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_15_wbjbct_uq2lfo.png"), auto;
    }
    64%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_16_hssi90_gygwtu.png"), auto;
    }
    68%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_17_niqqak_bbrba9.png"), auto;
    }
    72%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_18_trznuv_dqr9v8.png"), auto;
    }
    76%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_19_uttdy3_srfvio.png"), auto;
    }
    80%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_20_aeaw1e_w0whwv.png"), auto;
    }
    84%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_21_gahhqa_pof2sm.png"), auto;
    }
    88%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_22_e4r7am_dlqxnv.png"), auto;
    }
    92%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627408/hex-nft/assets/images/Layer_23_qrt91e_wmlobp.png"), auto;
    }
    96%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627409/hex-nft/assets/images/Layer_24_jh18cx_yxsmys.png"), auto;
    }
    100%{ 
        cursor: url("https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1676627409/hex-nft/assets/images/Layer_25_gm0goy_zwjhho.png"), auto;
    }
  }
@media only screen and (max-width:575px){
    .fullpage-loader-holder .img-loader { width: 100px; height: 100px;}
}