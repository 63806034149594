#myntist-header {background: rgba(19, 19, 19, 0.8);padding: 23px 130px;position: fixed;width: 100%;z-index: 999999;top: 0;}
#myntist-header .logo {width: 170px;}
#myntist-header .navbar .navbar-nav {transition: all ease-in-out 0.1s;}
#myntist-header .navbar .navbar-nav .nav-link {color: #fff;text-transform: uppercase;opacity: 0.6;font-family: 'Neon';font-weight: 900;font-size: 16px;line-height: 20px;overflow: hidden; }
#myntist-header .navbar .navbar-nav .nav-link:hover {opacity: 1;}
#myntist-header .navbar .navbar-nav li.active a{overflow: visible;}
#myntist-header .navbar .navbar-nav .nav-link::before {content: "-";position: absolute;top: -7px;left: -23px;font-size: 38px;color: #21FAFF;transition: all ease-in-out 0.4s;}
#myntist-header .navbar .navbar-nav .nav-link:hover::before{left: 100%;transition: all ease-in-out 0.4s;}
#myntist-header .navbar .navbar-nav li.active a::before{display: none;}
#myntist-header .navbar .navbar-nav li {margin: 0 28px 0 0;}
#myntist-header .navbar .navbar-nav li.active a {color: #21FAFF;opacity: 1;}
#myntist-header .navbar .navbar-nav li.active a:after {content: "";width: 100%;height: 4px;position: absolute;left: 50%;top: -34px;border-radius: 10px;transform: translateX(-50%);background: #21FAFF;transition: all ease-in-out 0.3s;}
#myntist-header form.search-form.transition {position: relative;text-align: end;}
#myntist-header .search-icon-inner {width: 44px;height: 44px;border-radius: 6px;display: flex;justify-content: center;align-items: center;border: 1px solid #1554f0;color: #1554f0;background-color: #1a1a1a;position: relative;right: 0;padding: 13px;transition: all ease-in-out 0.3s;cursor: pointer;user-select: none;}
#myntist-header .search-icon-inner .blue-logo{display: block;}
#myntist-header .search-icon-inner .white-logo{display: none;}
#myntist-header .search-icon-inner .cyan-logo{display: none;}
#myntist-header .search-icon-inner.active, #myntist-header .search-icon-inner:hover{border: 1px solid #1554f0;background-color: #1554f0;color: #fff;}
#myntist-header .search-icon-inner.active .blue-logo, #myntist-header .search-icon-inner:hover .blue-logo{display: none;}
#myntist-header .search-icon-inner.active .white-logo, #myntist-header .search-icon-inner:hover .white-logo{display: block;}
#myntist-header .search-icon-inner:hover{ border: 1px solid #1554f0;color: #1a1a1a;background-color:#1554f0; }
#myntist-header .myntist-login-button {border: 1px solid #21FAFF;color: #21FAFF;background-color: #1a1a1a;border-radius: 6px;padding: 11px 24px;font-family: 'Neon';font-weight: 900;font-size: 16px; transition: all ease-in-out 0.3s;position: relative;}
#myntist-header .myntist-login-button:hover {border: 1px solid #21FAFF;color: #1a1a1a;background-color: #21FAFF;}
#myntist-header .search-span {margin-left: 15px;}
#myntist-header .button-span {margin-left: 12px;}
#myntist-header input {border: 2px solid #1554f0;border-radius: 6px;background-color: #1a1a1a;font-family: 'Neon';font-weight: 900;font-size: 12px;height: 42px; margin-right: 2px;}
#myntist-header input:focus {outline: none !important;background-color: #1a1a1a !important;box-shadow: none !important;-webkit-text-fill-color: #fff !important;caret-color: white;}
#myntist-header .input-width{width: 450px;transition: all ease-in-out 0.3s;height: 44px;margin-right: 0px;margin-right: 0px;position: absolute; right: 0;}
#myntist-header .input-width:-webkit-autofill, 
#myntist-header .input-width:-webkit-autofill:hover, 
#myntist-header .input-width:-webkit-autofill:focus {caret-color: #f2f2f2;-webkit-text-fill-color: #fff !important;-webkit-box-shadow: none !important;box-shadow: none !important;transition: background-color 1000s ease-in-out 0s !important;border-radius: 10px;}
#myntist-header .input-no-width{width: 41px;transition: all ease-in-out 0.3s;position: absolute; right: 0;}
#myntist-header .dropdown .myntist-login-button {padding: 9px 24px;max-width: 208px;}
#myntist-header .dropdown .myntist-login-button .wallet-address {width: 120px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;font-family: 'Montserrat';}
#myntist-header .dropdown-menu {width: 100%;background-color: #333639;box-shadow: 0px 0px 7px #21FAFF;}
#myntist-header .dropdown-menu .dropdown-item{color: #fff !important;}
#myntist-header .dropdown-menu .dropdown-item:focus, 
#myntist-header .dropdown-menu .dropdown-item:hover {color: #1a1a1a !important;background-color: #21FAFF !important;}
#myntist-header .spiner-loader{width: 44px;height: 44px;border: 2px solid #1554f0;border-radius: 6px;display: flex;justify-content: center;align-items: center;}
#myntist-header .spiner-loader .spinner-border {width: 25px;height: 25px;border: 0.25em solid rgba(255, 255, 255, 0.4) !important;border-right-color: #1554f0 !important;}
#myntist-header .myntist-login-button-mobile{display: none;}
/**/
#myntist-header .notification-span {width: 44px;height: 44px;border-radius: 6px;display: flex;justify-content: center;align-items: center;border: 1px solid #1554f0;color: #1554f0;background-color: #1a1a1a;position: relative;right: 0;padding: 13px;transition: all ease-in-out 0.3s;cursor: pointer;margin-left: 15px;}
#myntist-header .notification-span .wallet-address{min-width: 20px;height: 20px;max-width: 43px;border-radius: 5px;background-color: #1554f0;color: #fff;position: absolute;right: -9px;top: -14px;font-size: 12px;padding: 1px 5px;overflow: hidden;text-overflow: ellipsis;display: inline-block;}
#myntist-header .notification-span:hover{ border: 1px solid #1554f0;color: #fff;background-color:#1554f0; }
.infinite-scroll-component__outerdiv .infinite-scroll-component.notifications-scroll{max-height: 600px;}
.no-notifications-section{height: 150px;}
#myntist-header .notification-span:hover{color: #fff;}
/* responsive */
@media only screen and (max-width:1919px){
    #myntist-header {padding: 23px 120px;}
    #myntist-header .navbar .navbar-nav li {margin: 0 16px 0 0;}
}
@media only screen and (max-width:1799px){
    #myntist-header .navbar .navbar-nav .nav-link {font-size: 14px;}
    #myntist-header .navbar .navbar-nav li {margin: 0 15px 0 0;}
    #myntist-header {padding: 23px 100px;}
    #myntist-header .dropdown .myntist-login-button {padding: 9px 10px;}
}
@media only screen and (max-width:1599px){
    #myntist-header .navbar .navbar-nav .nav-link {font-size: 12px;}
    #myntist-header .navbar .navbar-nav li {margin: 0 12px 0 0;}
    #myntist-header .myntist-login-button {font-size: 14px;}
    #myntist-header .search-icon-inner {width: 41px;height: 41px;}
    #myntist-header .notification-span {width: 41px;height: 41px;}
    #myntist-header input {height: 40px;}
    #myntist-header .input-width {height: 42px;}
    #myntist-header .logo {width: 165px;}
    #myntist-header .input-no-width { width: 38px;}
    #myntist-header .dropdown .myntist-login-button {padding: 9px 7px;}
    #myntist-header .spiner-loader { width: 41px; height: 41px;}
    #myntist-header {padding: 23px 80px;}
    #myntist-header .dropdown .myntist-login-button .wallet-address {width: 100px;}
}
@media only screen and (max-width:1399px){
    #myntist-header .navbar .navbar-nav .nav-link {font-size: 11px;padding: 0px 5px;}
    #myntist-header .navbar .navbar-nav li {margin: 0 8px 0 0;}
    #myntist-header .search-span {margin-left: 20px;}
    #myntist-header {padding: 23px 30px;}
    #myntist-header .navbar .navbar-nav li:last-of-type{margin: 0 0px 0 0;}
    /**/
    #myntist-header .navbar .navbar-nav li.active a:after {top: -41px;}
}
@media only screen and (max-width:1199px){
    #myntist-header .navbar .navbar-nav .nav-link {font-size: 11px;padding: 3px 0;}
    #myntist-header .navbar .navbar-nav li {margin: 0 13px 0 0;}
    #myntist-header .logo {width: 130px;}
    #myntist-header .myntist-login-button {padding: 11px 18px;font-size: 12px;}
    #myntist-header .dropdown .myntist-login-button .wallet-address {display: none;}
    #myntist-header .dropdown-menu[data-bs-popper] {left: -110px;}
    #myntist-header {padding: 23px 15px;}
    #myntist-header .dropdown .myntist-login-button {padding: 13px 7px;}
    /**/
    #myntist-header .navbar .navbar-nav li.active a:after {top: -34px;}
}
@media only screen and (max-width:991px){
    #myntist-header .navbar-light .navbar-toggler{color: #21FAFF !important;border: none !important;padding: 0 !important;position: absolute;right: 20px;top: 50%;transform: translateY(-50%);z-index: 99;font-size: 25px;}
    #myntist-header .navbar-toggler:focus{box-shadow: none !important;}
    #myntist-header .button-span {margin-right: 45px;}
    #myntist-header .navbar{position: static !important;}
    #myntist-header #basic-navbar-nav {position: absolute;top: 100%;left: 20px;right: 20px;background: #333639;z-index: 9999;}
    #myntist-header .navbar .navbar-nav li.active a:after{display: none;}
    #myntist-header .navbar .navbar-nav .nav-link::before{display: none;}
    #myntist-header .navbar .navbar-nav .nav-link {display: inline-block;padding: 10px 15px;}
    #myntist-header .navbar-nav{display: flex !important;}
    #myntist-header .input-width {width: 350px;}
    #myntist-header .dropdown .myntist-login-button .wallet-address {width: 102px;}
    #myntist-header .spiner-loader {width: 38px;height: 38px;}
    #myntist-header .spiner-loader .spinner-border {width: 20px;height: 20px;}
    #myntist-header .search-icon-inner {border: none;padding: 0px;}
    #myntist-header .myntist-login-button-mobile{display: block;font-size: 25px;}
    #myntist-header .myntist-login-button{display: none;}
    #myntist-header .search-icon-inner .blue-logo{display: none;}
    #myntist-header .search-icon-inner .cyan-logo{display: block;}
    #myntist-header .search-icon-inner.active .white-logo{display: none;}
    #myntist-header .search-icon-inner.active {border: none;background-color: transparent;color: #fff;padding: 0;width: 25px;height: 25px;}
    #myntist-header .search-icon-inner{width: 25px;height: 25px;}
    #myntist-header form.search-form.transition {position: static;}
    #myntist-header .input-width {width: auto !important;position: absolute;top: 80%;left: 10px;right: 10px;margin-right: 0px;z-index: 9999;padding: 7px 15px;}
    #myntist-header .input-no-width{width: 0 !important;position: absolute;height: 0;top: 100%;border: none;background-color: transparent; right: 0;padding: 0;}
    #myntist-header .dropdown .myntist-login-button {padding: 0;border: none;font-size: 25px;}
    #myntist-header .dropdown .myntist-login-button .wallet-address {display: none;}
    #myntist-header .myntist-login-button:hover {border: none;color: #21FAFF;background-color:transparent;}
    #myntist-header .dropdown-menu[data-bs-popper] {left: -70px;}
    /**/
    #myntist-header .notification-span {border: none;padding: 0;}
    #myntist-header .notification-span .fa-bell{font-size: 25px;color: #21FAFF;}
    #myntist-header .notification-span .wallet-address { right: -5px;top: -12px;}
    /**/
    #myntist-header .search-icon-inner .white-logo{display: none;}
    #myntist-header .search-icon-inner .blue-logo{display: none;}
    #myntist-header .search-icon-inner:hover {border: 1px solid transparent;color: #1a1a1a;background-color: transparent;}
    #myntist-header .notification-span:hover {border: 1px solid transparent;color: #fff;background-color: transparent;}
    /**/
    /**/
    #myntist-header .myntist-login-button, #myntist-header .notification-span {background-color: transparent;}
}
@media only screen and (max-width:767px){
    #myntist-header .search-span { margin-left: 0px;}
    #myntist-header input { height: 40px; width: 40px !important; margin-right: 2px;}
    #myntist-header input { font-size: 11px;}
    #myntist-header .dropdown .myntist-login-button .wallet-address {width: 65px;}
    #myntist-header .button-span {margin-right: 40px;}
    #myntist-header .spiner-loader {width: 38px;height: 38px;position: absolute;right: 50px;top: 52%;transform: translateY(-50%);}
    #myntist-header .navbar-light .navbar-toggler {font-size: 20px;}
    #myntist-header .dropdown .myntist-login-button {font-size: 20px;}
    #myntist-header .search-icon-inner {width: 20px;height: 20px;}
    #myntist-header .search-icon-inner.active {width: 20px;height: 20px;}
}
@media only screen and (max-width:575px){
    #myntist-header input {margin-right: 0px;}
}
@media only screen and (max-width:479px){
    #myntist-header .dropdown .myntist-login-button .wallet-address {width: 90px;}
    #myntist-header .navbar-light .navbar-toggler {font-size: 18px;}
    #myntist-header .dropdown .myntist-login-button {font-size: 18px;}
    #myntist-header .search-icon-inner {width: 18px;height: 18px;}
    #myntist-header .search-icon-inner.active {width: 18px;height: 18px;}
    #myntist-header .button-span {margin-right: 32px;}
    #myntist-header .dropdown-menu[data-bs-popper] {left: -80px;}
    /**/
    #myntist-header .notification-span {margin-left: 0px;}
    #myntist-header .notification-span .fa-bell {font-size: 20px;}
    #myntist-header .button-span {margin-right: 29px;margin-left: 2px;}
}
@media only screen and (max-width:319px){
    #myntist-header .navbar-light .navbar-toggler {font-size: 15px;}
    #myntist-header .dropdown .myntist-login-button {font-size: 15px;}
    #myntist-header .search-icon-inner {width: 15px;height: 15px;}
    #myntist-header .search-icon-inner.active {width: 15px;height: 15px;}
    #myntist-header .logo {width: 110px;}
}