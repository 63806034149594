#myntist-footer {background-color: #333639;padding: 45px 120px 0;}
#myntist-footer .footer-logo{width: 170px;}
#myntist-footer h3 {font-size: 20px;line-height: 25px;margin: 0 0 40px;font-family: 'Neon';font-weight: 900;}
#myntist-footer .footer-nav {font-size: 16px;line-height: 19px;}
#myntist-footer .footer-nav a {color: #fff;font-size: 18px; line-height: 22px;}
#myntist-footer .footer-nav li{margin-bottom: 20px;}
#myntist-footer .footer-nav a {display: inline-block;position: relative;text-decoration: none;font-family: 'Montserrat';    word-break: break-word; hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; -webkit-hyphens: auto; }
#myntist-footer .footer-nav a::after {content: '';position: absolute;width: 100%;transform: scaleX(0);border-radius: 5px;height: 0.2em;bottom: -4px;left: 0;background: currentcolor;transform-origin: bottom right;transition: transform 0.25s ease-out;color: #21FAFF;}
#myntist-footer .footer-nav a:hover{    color: #21FAFF;}
#myntist-footer .footer-nav a:hover::after {transform: scaleX(1);transform-origin: bottom left;color: #21FAFF;}
#myntist-footer .footer-nav a.active{    color: #21FAFF;}
#myntist-footer .footer-nav a.active::after {transform: scaleX(1);transform-origin: bottom left;color: #21FAFF;}
#myntist-footer .footer-nav-holder {margin: 0 0 100px;}
#myntist-footer .bottom-footer {padding: 35px 0;}
#myntist-footer .bottom-footer:before {content: "";height: 2px;position: absolute;left: 0;top: 0;right: 0;background: #1a1a1a;opacity: 0.3;}
#myntist-footer .copyright-text {font-size: 16px;line-height: 19px;}
#myntist-footer .about-hex p {margin-bottom: 60px;}
#myntist-footer .about-hex{max-width: 450px;}
/*social links*/
#myntist-footer .social-links{font-size:22px;flex-wrap: wrap;width: 80%;}
#myntist-footer .social-links li{margin:0 10px 10px 0;}
#myntist-footer .social-links a{width:46px;height:46px;overflow: hidden;background:#fff;}
#myntist-footer .social-links li.facebook a{color:#1877f2;}
#myntist-footer .social-links li.pinterest a{color:#c8232c;}
#myntist-footer .social-links li.tiktok a{color:#000000;}
#myntist-footer .social-links li.medium a{color:#02b875;}
#myntist-footer .social-links li.linkedin a{color:#0a66c2;}
#myntist-footer .social-links li.instagram a{color:#c32aa3;}
#myntist-footer .social-links li.youtube a{color:#ff0000;}
#myntist-footer .social-links li.twitter a{color:#00acee;}
#myntist-footer .social-links li.discord a{color:#5865f2;}
#myntist-footer .social-links li.reddit a{color:#ff4500;}
#myntist-footer .social-links li.github a{color:#171515;}
#myntist-footer .social-links li.telegram a{color:#0088cc;}
#myntist-footer .social-links li a:hover{color:#fff; }
#myntist-footer .social-links li.facebook a:hover{background:#1877f2;}
#myntist-footer .social-links li.pinterest a:hover{background:#c8232c;}
#myntist-footer .social-links li.tiktok a:hover{background:#000000;}
#myntist-footer .social-links li.medium a:hover{background:#02b875;}
#myntist-footer .social-links li.linkedin a:hover{background:#0a66c2;}
#myntist-footer .social-links li.instagram a:hover{background:#c32aa3;}
#myntist-footer .social-links li.youtube a:hover{background:#ff0000;}
#myntist-footer .social-links li.twitter a:hover{background:#00acee;}
#myntist-footer .social-links li.discord a:hover{background:#5865f2;}
#myntist-footer .social-links li.reddit a:hover{background:#ff4500;}
#myntist-footer .social-links li.github a:hover{background:#171515;}
#myntist-footer .social-links li.telegram a:hover{background:#0088cc;}
#myntist-footer .social-links li.tiktok .fa-tiktok {color: #111111;filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);}
/**/
#myntist-footer .gaming-img {width: 46px;height: 46px;border-radius: 50%;overflow: hidden;}
#myntist-footer .gaming-img img{width: 100%; height: 100%; object-fit: cover;}
#myntist-footer .gaming-head { color: #f2f2f2;cursor: pointer;}
#myntist-footer .gaming-head:hover { color: #21FAFF;}
/* responsive */
@media only screen and (max-width:1799px){
    #myntist-footer {padding: 45px 100px 0;}
}
@media only screen and (max-width:1599px){
    #myntist-footer h3 {font-size: 16px;}
    #myntist-footer .footer-nav a {font-size: 15px;}
    #myntist-footer .about-hex p {font-size: 14px;}
    #myntist-footer .about-hex {max-width: 362px;}
    #myntist-footer {padding: 45px 80px 0;}
}
@media only screen and (max-width:1399px){
    #myntist-footer h3 {font-size: 16px;}
    #myntist-footer .footer-nav a { font-size: 15px;}
    #myntist-footer .about-hex p {font-size: 15px;}
    #myntist-footer {padding: 45px 30px 0;}
}
@media only screen and (max-width:1199px){
    #myntist-footer .social-links { width: 100%;}
    #myntist-footer {padding: 45px 15px 0;}
    #myntist-footer .copyright-text p {font-size: 14px;}
}
@media only screen and (max-width:991px){
    #myntist-footer .about-hex{max-width:100%;margin:0 0 30px;}
    #myntist-footer .footer-widget{margin:0 0 30px;}
    #myntist-footer .footer-nav-holder{margin:0 0 50px;}
    #myntist-footer .web-owner-link { margin: 0 0 40px;}
}
@media only screen and (max-width:479px){
    #myntist-footer .copyright-text p {font-size: 13px;}
    #myntist-footer h3 { font-size: 13px;}
    #myntist-footer .footer-nav a { font-size: 14px;}
}
@media only screen and (max-width:319px){
    #myntist-footer {padding: 45px 6px 0;}
}